<template>
  <div class="row justify-content-center my-3">
    <div class="col text-start">
      <button class="btn btn-sm btn-secondary" @click="emitCollapseSidebar"  title="Cacher le volet du résumé de la commande">
        <i class="bi bi-layout-sidebar-inset"></i>
      </button>
    </div>

    <div class="col text-center">
      <img src="https://erp.belisoft.fr/backoffice/ErPstockv1/templates/img/icon-fraise-espaceplaisir.png" class="img-fluid" alt="logo espaceplaisir">
    </div>
    <div class="col dummy">
    </div>
  </div>
  <div class="row justify-content-center mb-3">
    <div class="col-xxl-7 col-10 h4 fw-bold bg-white border text-center p-3"># {{ order.increment_id }}</div>
  </div>
  <div class="row flex-column align-items-center">
    <div class="col-auto fs-5 fw-bold">
      {{ order.customer_firstname }} {{ order.customer_lastname }}
    </div>
    <div class="col-auto fs-sm">{{ order.customer_email }}</div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitCollapseSidebar() {
      this.$emit('collapse-sidebar');
    },
  },
};
</script>
