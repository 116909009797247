<template>

  <div v-if="product" class="row">
    <div class="col-12 text-start">
      <div class="row mb-2">
        <div class="col-12">
          <img v-if="product && product.media_gallery_entries && product.media_gallery_entries.length > 0" class="img-fluid" :src="productImageUrl(product.media_gallery_entries[0].file)" alt="Product Image">
        </div>
      </div>

      <div class="row mb-2">
        <div v-if="product.stocks" class="col-12 fw-light fs-sm">Stock : {{ product.stocks.quantity }}</div>
        <div v-else class="col-12 fw-light fs-sm">chargement...</div>
      </div>
    </div>
  </div>

  <div v-else class="row p-2 justify-content-center">
    <loader />
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import { round, productImageUrl } from '@/utils/tools';
import productService from '@/services/productService';

export default {
  props: {
    sku: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      product: null
    };
  },
  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    productEan() {
      return this.product?.custom_attributes?.find(attr => attr.attribute_code == 'ean')?.value || 'N/A';
    },
    productBrand() {
      return this.data?.custom_attributes.find(attr => attr.attribute_code == 'brand')?.value || 'N/A';
    },
    productSupplier() {
      return this.data?.custom_attributes.find(attr => attr.attribute_code == 'supplier')?.value || 'N/A';
    }
  },
  methods: {
    round, 
    productImageUrl,
  },
  async mounted() {
    this.product = await productService.getProduct(this.sku);
    this.product.stocks = await productService.getProductStockInStore(this.sku, this.user.store.source_code);
  },
}
</script>