<template>

  <div class="col d-flex flex-column h-100">
    <div class="row flex-grow-1 overflow-auto">
      
      <template v-if="error">{{ error }}</template>
      <template v-else-if="loading"><loader/></template>
      <template v-else>
        
        <draggable
          class="row row-cols-3 flex-grow-1 overflow-y-auto justify-content-center align-items-center align-content-center"
          :list="preparation.products"
          :group="{ name: 'preparation-products', pull: true, put: ['products'] }"
          :multi-drag="true"
          selected-class="selected"
          :sort="false"
          @add="addProduct">

          <template v-slot:item="{ element, index }">
            <article v-if="element" class="col-xxl-4 col-5 m-1" :itemKey="index">
              <preparation-product :product="element" @click="toggleProductSelection(element)" />
            </article>
          </template>
          
        </draggable>

      </template>
    </div>

    <div class="row align-items-center border-top py-1 ">
      <div class="col-auto">
        <span v-if="preparation.id_preparation"><strong>Préparation #{{ preparation.id_preparation }}</strong> - {{ displayInitDate }} {{ displayLastEvent }}</span>
        <span v-else-if="preparation.entity_id"><strong>Remis en boutique</strong> le {{ formatDate(preparation.created_at) }}</span>
        <span v-else ><strong>Nouvelle expédition</strong></span>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-danger" @click="closeWorkplan">Fermer</button>
      </div>
    </div>

    <div class="row border-top py-3 bg-light">
      <div class="col-12">

        <div class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">

          <div class="col-auto me-auto">
            <div class="row h-100">

              <action-card v-if="preparation.id_carrier && displayCarrier(preparation.id_carrier, preparation.id_carrier_mode)" 
                :title="'Transporteur'" 
                :modal="'editCarrierModal'"
                :content="displayCarrier(preparation.id_carrier, preparation.id_carrier_mode).name + '\n' + displayRelay() ?? ''"
                :icon="isEditable ? 'bi-pencil-square' : ''" 
                :class="isEditable ? 'pe-auto' : 'pe-none'" />

              <action-card v-if="preparation.address && preparation.id_carrier != 0 && preparation.id_carrier != 16" 
                :title="'Adresse'"
                :modal="'editAddressModal-shipping'"
                :content="formattedAddress"
                :icon="isEditable ? 'bi-pencil-square' : ''" 
                :class="isEditable ? 'pe-auto' : 'pe-none'" />

            </div>
          </div>

          <div class="col-auto">
            <div class="row h-100">

              <action-button v-if="showDeleteButton" :icon="'bi-trash'" :text="'Retirer\n les produits'" :color="'white'" :type="'danger'" @click="deleteSelectedProducts" />
              <action-button v-if="showUpdateButton" :icon="'bi-cloud-upload'" :text="'Mettre à jour\nla préparation'" :color="'white'" :type="'primary'" @click="updatePreparation" />
              <action-button v-if="showCancelButton" :icon="'bi-x-square'" :text="'Annuler\n la préparation'" :color="'white'" :type="'danger'" @click="cancelPreparation" />
              <action-button v-if="showCreateButton" :icon="'bi-box2-heart'" :text="'Nouv. expé.\nlogistique'" :color="'white'" :type="'primary'" @click="createPreparation(preparation)" />
              <action-button v-if="showInStoreButton" :icon="'bi-bag-check'" :text="'Remis\nen boutique'" :color="'white'" :type="'success'" @click="createShipment(preparation)" />

            </div>
          </div>
          
        </div>

      </div>
    </div>
  </div>

  <!-- Modals -->
  <edit-address-modal :address="preparation.address" :type="'shipping'" @update-address="updateAddress" />
  <edit-carrier-modal v-if="order && order.carriers" :carriers="order.carriers" />

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, capitalizeEachWord, horizontalScroll } from '@/utils/tools';
import PreparationProduct from '@/components/order/OrderPreparationProduct';
import ActionCard from '@/components/common/ActionCard';
import ActionButton from '@/components/common/ActionButton';
import EditAddressModal from '@/components/modals/EditAddressModal';
import EditCarrierModal from '@/components/modals/EditCarrierModal';

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      selection: [],
    };
  },
  components: {
    PreparationProduct,
    ActionCard,
    ActionButton,
    EditAddressModal,
    EditCarrierModal,
  },
  computed: {
    ...mapGetters('cookie', [
      'user'
    ]),
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('preparation', [
      'preparation', 
      'loading', 
      'error'
    ]),
    isEditable() {
      return this.preparation.id_preparation_state == 0 || this.preparation.id_preparation_state == 1;
    },
    showCreateButton() {
      return this.preparation.id_preparation_state == 0 && this.preparation.products.length > 0;
    },
    showInStoreButton() {
      return (this.user.id_role == 1 || this.user.id_profile == 4 || this.user.id_profile == 2) && this.preparation.id_preparation_state == 0 && this.preparation.products.length > 0;
    },
    showDeleteButton() {
      return (this.preparation.id_preparation_state == 0 || this.preparation.id_preparation_state == 1) && this.selection.length > 0;
    },
    showCancelButton() {
      return this.preparation.id_preparation_state == 1;
    },
    showUpdateButton() {
      return this.preparation.id_preparation_state == 1;
    },
    formattedAddress() {
      const address = this.preparation.address;
      if (!address) return '';
      if (this.preparation.id_preparation) {
        return `${address.firstname} ${address.lastname}\n${address.address1}\n${address.address2}\n${address.postcode} ${address.city}\n${address.telephone}`;
      }
      return `${address.firstname} ${address.lastname}\n${address.street.join('\n')}\n${address.postcode} ${address.city}\n${address.telephone}`;
    },
    displayState(id_state) {
      return this.states && this.states.preparations ? this.states.preparations.find(state => state.id_preparation_state == id_state) : null;
    },
    displayInitDate() {
      return 'Initiée le ' + formatDate(this.preparation.date_add, 'slash') + ' à ' + formatDate(this.preparation.date_add, 'time'); 
    },
    displayLastEvent() {
      if (this.preparation.date_packing) {
        return ' - Expédiée le ' + formatDate(this.preparation.date_packing, 'slash') + ' à ' + formatDate(this.preparation.date_packing, 'time');
      } else if (this.preparation.id_preparation_state > 1) {
        return ' - ' + capitalizeEachWord(this.preparation.state_name) + ' le ' + formatDate(this.preparation.date_event, 'slash') + ' à ' + formatDate(this.preparation.date_event, 'time');
      } else return null;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(preparation) {
      
        if (preparation == 'initPreparation') {
          await this.initPreparation({ order: this.order });

        } else if (preparation.id_preparation) { 
          await this.fetchPreparation(preparation.id_preparation);

        } else if (preparation.entity_id) { 
          await this.fetchShipment(preparation.entity_id);
        }
      },
    }
  },
  methods: {
    ...mapActions('order', [
      'fetchOrderStores',
    ]),
    ...mapActions('preparation', [
      'fetchPreparation', 
      'fetchShipment',
      'initPreparation', 
      'createShipment', 
      'createPreparation', 
      'addProduct', 
      'deleteProduct', 
      'editQuantity', 
      'updateAddress',
      'updatePreparation',
      'cancelPreparation',
      'clearPreparation',
    ]),
    formatDate, 
    capitalizeEachWord,
    horizontalScroll,
    displayCarrier(id_carrier, id_carrier_mode) {
      return this.order && this.order.carriers ? this.order.carriers.find(carrier => carrier.id_carrier_wms == id_carrier && carrier.id_carrier_mode_wms == id_carrier_mode) : null;
    },
    displayRelay() {
      return this.order.stores && this.preparation.address.id_pr ? this.order.stores.find(store => store.id_pickup == this.preparation.address.id_pr).firstname : '';
    },
    toggleProductSelection(product) {
      const index = this.selection.findIndex(p => p.sku == product.sku);
      if (index == -1) this.selection.push(product);
      else this.selection.splice(index, 1);
    },
    deleteSelectedProducts() {
      this.preparation.products = this.preparation.products.filter(product => 
        !this.selection.some(selected => selected.sku == product.sku)
      );
      this.selection = [];
    },
    closeWorkplan() {
      this.clearPreparation();
      this.$emit('close-workplan');
    }
  },
  mounted() {
    this.fetchOrderStores();
  }
}
</script>
