<template>
  <div v-if="data" class="row align-items-center border">
    <div class="col-2 d-flex align-items-center h-64 p-0">
      <img v-if="productData.media_gallery_entries.length > 0" class="img-fluid h-64" :src="productImageUrl(productData.media_gallery_entries[0].file)" alt="Product Image">
      <img v-else class="img-fluid h-64" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
    </div>    
    <div class="col d-flex align-items-center fw-bold lh-sm">{{ productData.name }}</div>
    <div class="col-2 square-64 d-flex justify-content-center align-items-center fw-bold border" :class="colorShipped(product)">
      <div v-if="!editQuantity">
        <span @click="startEditing">{{ productData.quantity_sent }}/{{ productData.quantity_to_send }}</span>
      </div>
      <div v-else>
        <input type="text" v-model.number="editableQuantity" @blur="stopEditing" @keyup.enter="stopEditing" class="form-control form-control-sm text-center" />
      </div>
    </div>
  </div>
  <div v-else class="row h-120 align-items-center">
    <div class="col-12">
      <loader/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { productImageUrl } from '@/utils/tools';
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      data: null,
      editQuantity: false,
      editableQuantity: this.product.quantity_to_send,
    };
  },
  watch: {
    product: {
      handler(value) {
        this.editableQuantity = value.quantity_to_send;
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('preparation', [
      'preparation'
    ]),
    productData() {
      return {
        ...this.data,
        ...this.product,
      };
    }
  },
  methods: {
    productImageUrl,
    colorShipped(product) {
      if (this.preparation.id_preparation_state == '9') {
        return 'border-secondary bg-secondary-subtle';
      } else if (product.quantity_sent < product.quantity_to_send) {
        return 'border-warning bg-warning-subtle';
      } else if (product.quantity_sent == 0 && product.quantity_to_send == 0) {
        return 'border-secondary bg-secondary-subtle';
      } else if (product.quantity_sent == product.quantity_to_send) {
        return 'border-success bg-success-subtle';
      } else if (product.quantity_sent > product.quantity_to_send) {
        return 'border-danger bg-danger-subtle';
      }
    },
    startEditing() {
      if (this.preparation.id_preparation_state == 1) {
        this.editQuantity = true;
      }
    },
    stopEditing() {
      this.editQuantity = false;
      this.updateQuantity();
    },
    updateQuantity() {
      console.log('coucou')
    }
  },
  async mounted() {
    this.data = await productService.getProduct(this.product.sku);
  },
}
</script>