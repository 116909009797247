import productService from '@/services/productService';

export default {
  namespaced: true,
  state: {
    product: null,
  },
  mutations: {
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_PRODUCT_STOCK(state, stock) {
      state.product = { ...state.product, stock };
    },
    CLEAR_PRODUCT(state) {
      state.product = null;
    },
  },
  actions: {
    async fetchProduct({ commit, dispatch }, sku) {
      try {
        dispatch('loader/load', true, { root: true });
        commit('SET_PRODUCT', await productService.getProduct(sku));
      } catch (error) {
        dispatch('loader/error', `Failed to fetch product: ${error.message}`, { root: true });
      } finally {
        dispatch('loader/load', false, { root: true });
      }
    },
    async fetchProductStockInStore({ commit }, { sku, source_code }) {
      try {
        commit('SET_PRODUCT_STOCK', await productService.getProductStockInStore(sku, source_code));
      } catch (error) {
        console.log(error)
      }
    },
    async updateStock({ dispatch }, { sku, source_code, quantity }) {
      const sourceItems = {
        sourceItems: [{
          sku: sku,
          source_code: source_code,
          quantity: quantity,
          status: 1,
        }]
      };
      try {
        await productService.updateStock(sku, sourceItems);
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed to update stock.' }, { root: true });
      }
    },
    async clearProduct({ commit }) {
      commit('CLEAR_PRODUCT');
    },
  },
  getters: {
    product: (state) => state.product,
  },
};
