import { createStore } from 'vuex';
import address from './modules/address';
import alerts from './modules/alerts';
import cart from './modules/cart';
import catalog from './modules/catalog';
import cookie from './modules/cookie';
import customer from './modules/customer';
import loader from './modules/loader';
import order from './modules/order';
import preparation from './modules/preparation';
import product from './modules/product';
import reception from './modules/reception';
import refund from './modules/refund';
import rma from './modules/return';
import search from './modules/search';
import states from './modules/states';
import store from './modules/store';
import switchboard from './modules/switchboard';
import user from './modules/user';

export default createStore({
  modules: {
    address,
    alerts,
    cart,
    catalog,
    cookie,
    customer,
    loader,
    order,
    preparation,
    product,
    reception,
    refund,
    rma,
    search,
    states,
    store,
    switchboard,
    user,
  }
});