<template>
  <div class="row justify-content-center mt-5">
    <div class="col-auto h5 fw-bold">Informations client</div>
  </div>
  <div class="row justify-content-center">
    <div v-if="customer" class="col">
      <table class="table table-borderless align-middle fs-sm">
        <thead>
          <tr>
            <th class="text-start bg-light" colspan="3">Autres commandes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(order, index) in customer.orders" :key="index">
            <td class="text-start col-4">{{ formatDate(order.created_at).date }}</td>
            <td class="text-center col-4">{{ order.status }}</td>
            <td class="text-end col-4"><a :href="order.increment_id"># {{ order.increment_id }}</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/tools';
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate,
  }
};
</script>