<template>
  <div class="modal fade" :id="'editAddressModal-' + type" tabindex="-1" aria-labelledby="editAddressModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title">Modifier l'adresse</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <form class="row g-2 align-items-center" @submit.prevent="submit" autocomplete="off">
            <div class="row g-2 align-items-center">
              <div class="col">
                <input v-model="form.address.firstname" type="text" class="form-control" placeholder="Prénom" aria-label="Prénom" required>
              </div>
              <div class="col">
                <input v-model="form.address.lastname" type="text" class="form-control" placeholder="Nom" aria-label="Nom" required>
              </div>
            </div>
            <div class="row g-2 align-items-center">
              <div class="col">
                <input v-model="form.address.email" type="text" class="form-control" placeholder="Adresse e-mail" aria-label="Adresse e-mail" required>
              </div>
              <div class="col">
                <input v-model="form.address.telephone" type="text" class="form-control" placeholder="Numéro de téléphone" aria-label="Numéro de téléphone" required>
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <input v-model="form.address.street[0]" type="text" class="form-control" placeholder="Numéro et rue" aria-label="Adresse">
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <input v-model="form.address.street[1]" type="text" class="form-control" placeholder="Appartement, batiment, étage ..." aria-label="Adresse 2">
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <input v-model="form.address.postcode" type="text" class="form-control" placeholder="Code postal" aria-label="Code postal">
              </div>
              <div class="col">
                <input v-model="form.address.city" type="text" class="form-control" placeholder="Ville" aria-label="Ville">
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <select v-model="form.address.region_id" class="form-control" aria-label="Région">
                  <option v-for="region in regions" :key="region.region_id" :value="region.region_id">
                    {{ region.default_name }}
                  </option>
                </select>              
              </div>
              <div class="col">
                <select v-model="form.address.country_id" class="form-control" aria-label="Pays"> 
                  <option v-for="country in countries" :key="country.country_id" :value="country.country_id">
                    {{ country.country_id }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row g-2">
              <div class="col d-flex align-items-center">
                <input v-model="form.address.updateBothAddress" type="checkbox" class="form-check-input m-0" :id="'update' + type + 'Checkbox'" checked>
                <label class="form-check-label mx-1" :for="'update' + type + 'Checkbox'" role="button">
                  Modifier aussi l'adresse de {{  type == 'billing' ? 'livraison' : 'facturation' }}
                </label>           
              </div>
              <div class="col text-end">
                <button type="submit" class="btn btn-success" data-bs-dismiss="modal" aria-label="Close">Valider</button>
              </div>
            </div>
          </form>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    address: {
      type: Object,
      required: true,
      default: () => ({
        firstname: '',
        lastname: '',
        email: '',
        telephone: '',
        street: ['', ''],
        postcode: '',
        city: '',
        region_id: null,
        country_id: null,
        updateBothAddress: false,
      })
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      form: {
        address: {
          firstname: null,
          lastname: null,
          email: null,
          telephone: null,
          street: ['', ''],
          postcode: null,
          city: null,
          region_id: null,
          country_id: null,
          updateBothAddress: false,
        }
      }
    };
  },
  watch: {
    address: {
      handler(value, old) {
        if (JSON.stringify(value) !== JSON.stringify(old)) {

          this.form.address.firstname = value.firstname || '';
          this.form.address.lastname = value.lastname || '';
          this.form.address.email = value.email || '';
          this.form.address.telephone = value.telephone || '';
          this.form.address.street = Array.isArray(value.street) ? [...value.street] : ['', ''];
          this.form.address.postcode = value.postcode || '';
          this.form.address.city = value.city || '';
          this.form.address.region_id = value.region_id || null;
          this.form.address.country_id = value.country_id || null;
          this.form.address.updateBothAddress = value.updateBothAddress || false;

          if (!this.countries) {
            this.fetchCountries();
          }
          if (this.form.address.country_id) {
            this.fetchRegions(this.form.address.country_id);
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters('address', [
      'address',
      'countries', 
      'regions'
    ]),
  },
  methods: {
    ...mapActions('address', [
      'fetchCountries', 
      'fetchRegions'
    ]),
    submit() {
      console.log('this.form.address')
      this.$emit('update-address', { type: this.type, address: this.form.address });
    }
  },
};
</script>
