<template>
  <div class="row bg-light">
    <div class="col-12 px-5">
      
      <!-- Filtres -->
      <div class="row align-items-start bg-light py-3">
        <div class="col">
          <search-filters :filters="activeFilters" @submit.prevent="searchProducts" />
        </div>
        
        <div class="col-lg-auto col-1">
          <div class="row">
            <div class="col-lg-6 col-12 mb-3">
              <button type="submit" class="btn btn-primary" form="search">Rechercher</button>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button" @click="toggleFilterDropdown">
                Critères
              </a>
              <div v-if="showFilterDropdown" class="dropdown-menu show position-fixed end-0 mt-3 me-3 p-3">
                <div class="form-check" v-for="filter in filters" :key="filter.field">
                  <input class="form-check-input" type="checkbox" v-model="filter.active" />
                  <label class="form-check-label mt-1" :for="filter.field">{{ filter.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row overflow-y-auto">
    <div class="col-12 overflow-y-auto px-5 pt-5" style="max-height: 70vh;">
      <div v-if="search.loading" class="row h-100">
        <loader />
      </div>
      <div v-else-if="search.products.length" class="col">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" @click="sortByColumn('id')">ID</th>
              <th scope="col" @click="sortByColumn('sku')">SKU</th>
              <th scope="col" @click="sortByColumn('name')">Nom du produit</th>
              <th scope="col" @click="sortByColumn('created_at')">Créé le</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-for="product in sortedProducts" :key="product.id">
              <td class="py-3">{{ product.id }}</td>
              <td class="py-3">
                <router-link :to="'/products/' + product.sku" class="text-black text-decoration-none">
                  {{ product.sku }}
                </router-link>
              </td>
              <td class="py-3">
                <router-link :to="'/products/' + product.sku" class="text-black text-decoration-none">
                  {{ product.name }}
                </router-link>
              </td>
              <td class="py-3">{{ formatDate(product.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="col text-center">
        <p>Aucun produit n'a été trouvé.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, sourceIcon } from '@/utils/tools';
import SearchFilters from '@/components/common/SearchFilters';

export default {
  components: {
    SearchFilters,
  },
  data() {
    return {
      showFilterDropdown: false,
      filters: [
        { field: 'name', label: 'Nom', type: 'text', active: true, value: '', condition: 'like' },
        { field: 'sku', label: 'SKU', type: 'text', active: true, value: '', condition: 'eq' },
        { field: 'ean', label: 'ean', type: 'text', active: true, value: '', condition: 'eq' },
      ],
      sortBy: 'created_at', // Champ de tri par défaut
      sortDesc: true,       // Tri descendant par défaut
    };
  },
  computed: {
    ...mapGetters('search', ['search']),
    activeFilters() {
      return this.filters.filter(filter => filter.active);
    },
    sortedProducts() {
      let products = [...this.search.products];
      const compare = (a, b) => {
        const field = this.sortBy;
        if (a[field] < b[field]) return this.sortDesc ? 1 : -1;
        if (a[field] > b[field]) return this.sortDesc ? -1 : 1;
        return 0;
      };
      return products.sort(compare);
    }
  },
  methods: {
    ...mapActions('search', ['fetchProducts']),
    formatDate, sourceIcon,
    toggleFilterDropdown() {
      this.showFilterDropdown = !this.showFilterDropdown;
    },
    async searchProducts() {
      const filters = this.filters.reduce((acc, filter) => {
        if (filter.active && filter.value && filter.value.length) {
          if (filter.type == 'select' && Array.isArray(filter.value)) {
            acc[filter.field] = { value: filter.value.map(option => option.value).join(','), condition: 'in' };
          } else {
            acc[filter.field] = { value: filter.value, condition: filter.condition };
          }
        }
        return acc;
      }, {});
      filters.type_id = {
        value: 'simple',
        condition: 'eq',
      };
      await this.fetchProducts({ filters, limit: 100 });
    },
    sortByColumn(column) {
      if (this.sortBy == column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    }
  },
  mounted() {
    if (!this.search.products.length) {
      this.searchProducts();
    }
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block;
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 ::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}</style>