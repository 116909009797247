<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div class="row p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Réception à {{ user.store.name }}</h1>
        </div>
      </div>

      <div class="row h-64 justify-content-center border-bottom">
        <div class="col-xxl-10 col-12 d-flex">
          <search-bar :keywords="keywords" placeholder="Scannez un code-barre..." @search="onSearch" />
        </div>
      </div>

      <div v-if="search.loading" class="row h-100 overflow-auto">
        <loader />
      </div>

      <div v-else-if="product && product.stock" class="row h-100 justify-content-center overflow-y-auto">
        <div class="col-auto">
          <div class="row justify-content-center p-3">
            <div class="col-4 mb-3">
              <img class="img-fluid" :src="productImageUrl(product.media_gallery_entries[0].file)" alt="Product Image">
            </div>
            <div class="col-auto">
              <div class="row d-flex flex-column">
                <div class="col-auto fs-4 fw-bold mb-3">{{ product.name }}</div>
                <div class="col-auto fs-5 mb-2"><strong>Stock : </strong>{{ product.stock.quantity }}</div>
                <div class="col-auto fs-5 mb-2"><strong>Seuil : </strong>{{ product.stock.threshold }}</div>
                <div class="col-auto fs-5 mb-3"><strong>Localisation : </strong></div>
                <div class="col-auto mb-2">
                  <div class="input-group">
                    <input type="number" class="form-control" v-model="quantity" placeholder="Quantité à ajouter">
                    <button class="btn btn-primary" @click="addToStock">Ajouter au stock</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="search.error" class="mt-3">
        <div class="alert alert-primary text-start" role="alert">
          {{ search.error }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl } from '@/utils/tools';
import SearchBar from '@/components/common/SearchBar';

export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      keywords: '',
      quantity: null,
    };
  },
  watch: {
    'user.store': {
      handler(new_store, old_store) {
        if (new_store !== old_store) {
          this.fetchStore(new_store);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('product', ['product']),
    ...mapGetters('search', ['search']),
    ...mapGetters('cookie', ['user']),
  },
  methods: {
    ...mapActions('product', ['fetchProduct', 'fetchProductStockInStore', 'updateStock', 'clearProduct']),
    ...mapActions('search', ['fetchProducts', 'clearSearch']),
    ...mapActions('store', ['fetchStore']),
    productImageUrl,
    async onSearch(keywords) {
      this.keywords = keywords;
      await this.fetchProducts({ 
        filters: {  ean: { value: keywords, condition: 'eq' }},
        operation: 'or', 
        limit: 1, 
        fields: ['sku'] 
      });
      if (this.search.products.length > 0) {
        await this.fetchProduct(this.search.products[0].sku);
        await this.fetchProductStockInStore({ sku: this.product.sku, source_code: this.user.store.source_code });
      }
    },
    async addToStock() {
      if (this.quantity > 0) {
        await this.updateStock({
          sku: this.product.sku,
          source_code: this.user.store.source_code,
          quantity: this.product.stock.quantity + this.quantity,
        });
        this.fetchProductStockInStore({ sku: this.product.sku, source_code: this.user.store.source_code });
        this.quantity = 0;
      } else {
        alert('Veuillez entrer une quantité valide.');
      }
    },
  },
  mounted() {
    this.fetchStore(this.user.store);
  },
  unmounted() {
    this.clearSearch();
    this.clearProduct();
  }
};
</script>
