import storeService from '@/services/storeServices';

export default {
  namespaced: true,
  state: {
    stores: [],
    store: null,
  },
  mutations: {
    SET_STORES(state, stores) {
      state.stores = stores;
    },
    SET_STORE(state, store) {
      state.store = store;
    },
    SET_STORE_USERS(state, users) {
      state.store = { ...state.store, users };
    },
    SET_STORE_LOCATIONS(state, locations) {
      state.store = { ...state.store, locations };
    },
    SET_STORE_LOCATION(state, location) {
      state.store = { ...state.store, location };
    },
    SET_STORE_LOCATION_SHELVES(state, shelves) {
      state.store.location = { ...state.store.location, shelves };
    },
    SET_STORE_CASHIER(state, cashier) {
      state.store = { ...state.store, cashier };
    },
    SET_STORE_TRAFFIC(state, traffic) {
      state.store = { ...state.store, traffic };
    },
  },
  actions: {
    async fetchStores({ commit }) {
      try {
        commit('SET_STORES', await storeService.getStores());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStore({ state, commit }, store) {
      try {
        commit('SET_STORE', store);
        commit('SET_STORE_LOCATIONS', await storeService.getStoreLocations(state.store.id_store));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreUsers({ commit }, store_id) {
      try {
        commit('SET_STORE_USERS', await storeService.getStoreUsers(store_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreLocation({ state, commit }, location) {
      try {
        commit('SET_STORE_LOCATION', location);
        commit('SET_STORE_LOCATION_SHELVES', await storeService.getStoreLocationShelves(state.store.id_store, state.store.location.id_location));
      } catch (error) {
        console.error(error);
      }
    },
    async addStoreLocation({ state, dispatch }, location) {
      try {
        await storeService.addStoreLocation(state.store.id_store, location);
        dispatch('fetchStore', state.store);
      } catch (error) {
        console.error(error);
      }
    },
    async addLocationShelf({ state, dispatch }, shelf) {
      try {
        await storeService.addLocationShelf(state.store.id_store, state.store.location.id_location, shelf);
        dispatch('fetchStore', state.store);
        dispatch('fetchStoreLocation', state.store.location);
      } catch (error) {
        console.error(error);
      }
    },
    async addProductToShelf({ state, dispatch }, { id_shelf, product }) {
      try {
        await storeService.addProductToShelf(state.store.id_store, id_shelf, product);
        dispatch('fetchStore', state.store);
        dispatch('fetchStoreLocation', state.store.location);
      } catch (error) {
        console.error(error);
      }
    },
    async deleteProductFromShelf({ state, dispatch }, { id_shelf, product }) {
      try {
        if (confirm('Voulez-vous retirer ce produit de l\'étagère ?')) {
          await storeService.updateProductFromShelf(state.store.id_store, id_shelf, { id_product: product.id_product });
          dispatch('fetchStore', state.store);
          dispatch('fetchStoreLocation', state.store.location);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateLocationShelf({ state, dispatch }, { shelf }) {
      try {
        console.log(shelf)
        await storeService.updateLocationShelf(state.store.id_store, shelf.id_shelf, shelf);
        dispatch('fetchStore', state.store);
        dispatch('fetchStoreLocation', state.store.location);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreCashierHistory({ state, commit }) {
      try {
        commit('SET_STORE_CASHIER', await storeService.getStoreCashierHistory(state.store.id_store));
      } catch (error) {
        console.error(error);
      }
    },
    async insertStoreCashier({ state, dispatch }, payload) {
      try {
        await storeService.insertStoreCashier(state.store.id_store, payload);
      } catch (error) {
        console.error(error);
      }
      dispatch('fetchStoreCashierHistory', state.store.id_store)
    },
    async fetchStoreTrafficHistory({ state, commit }) {
      try {
        commit('SET_STORE_TRAFFIC', await storeService.getStoreTrafficHistory(state.store.id_store));
      } catch (error) {
        console.error(error);
      }
    },
    async insertStoreTraffic({ state, dispatch }, payload) {
      try {
        await storeService.insertStoreTraffic(state.store.id_store, payload);
      } catch (error) {
        console.error(error);
      }
      dispatch('fetchStoreTrafficHistory', state.store.id_store)
    },
  },
  getters: {
    stores: state => state.stores,
    store: state => state.store,
  }
};
