import searchService from '@/services/searchService';
import { shortcuts } from '@/utils/shortcuts';

export default {
  namespaced: true,
  state: {
    search: {
      carts: [],
      customers: [],
      orders: [],
      products: [],
      returns: [],
      refunds: [],
      order_id: null,
      loading: false,
      error: null,
    },
    shortcuts: shortcuts,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.search.loading = loading;
    },
    SET_ERROR(state, error) {
      state.search.error = error;
    },
    SET_CARTS(state, carts) {
      state.search.carts = carts;
    },
    SET_CUSTOMERS(state, customers) {
      state.search.customers = customers;
    },
    SET_ORDERS(state, orders) {
      state.search.orders = orders;
    },
    SET_PRODUCTS(state, products) {
      state.search.products = products;
    },
    SET_RETURNS(state, returns) {
      state.search.returns = returns;
    },
    SET_REFUNDS(state, refunds) {
      state.search.refunds = refunds;
    },
    CLEAR_SEARCH(state) {
      state.search = {
        carts: [],
        customers: [],
        orders: [],
        products: [],
        returns: [],
        refunds: [],
        order_id: null,
        loading: false,
        error: null,
      };
    },
  },
  actions: {
    async fetchCarts({ commit }, { filters, operation, limit }) {
      commit('SET_LOADING', true);
      try {
        const carts = await searchService.searchCarts(filters, operation, limit);
        commit('SET_CARTS', carts);
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchCustomers({ commit }, { filters, operation, limit }) {
      commit('SET_LOADING', true);
      try {
        const customers = await searchService.searchCustomers(filters, operation, limit);
        commit('SET_CUSTOMERS', customers);
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchOrders({ commit }, { filters, operation, limit }) {
      commit('SET_LOADING', true);
      try {
        const orders = await searchService.searchOrders(filters, operation, limit);
        commit('SET_ORDERS', orders);
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchReturns({ commit }, { filters, operation, limit }) {
      commit('SET_LOADING', true);
      try {
        commit('SET_RETURNS', await searchService.searchReturns(filters, operation, limit));
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchProducts({ commit }, { filters, operation, limit, fields }) {
      commit('SET_LOADING', true);
      try {
        commit('SET_PRODUCTS', await searchService.searchProducts(filters, operation, limit, fields));
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchRefunds({ commit }, { filters, operation, limit }) {
      commit('SET_LOADING', true);
      try {
        commit('SET_REFUNDS', await searchService.searchRefunds(filters, operation, limit));
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async clearSearch({ commit }) {
      commit('CLEAR_SEARCH');
    }
  },
  getters: {
    search: state => state.search,
    shortcuts: state => state.shortcuts,
  }
};