<template>
  <form class="row align-items-center" id="search">
    <div v-for="filter in filters" :key="filter.field" class="col-5 col-md-4 col-xxl-2 mb-2">
      <input v-if="filter.type == 'text'" v-model="filter.value" :placeholder="filter.label" class="form-control" />
      <input v-if="filter.type == 'number'" v-model="filter.value" :type="filter.type" :placeholder="filter.label" class="form-control" />
      <date-picker v-if="filter.type == 'date'" range valueType="YYYY-MM-DD" v-model:value="filter.value" separator=" - " format="DD/MM/YYYY" :shortcuts="shortcuts" :placeholder="filter.label" class="form-control border-0 p-0" />
      <multi-select v-if="filter.type == 'select'"
        v-model="filter.value"
        :options="filter.options"
        optionLabel="label"
        filter :placeholder="'Sélectionnez des boutiques'"
        :selectedItemsLabel="'{0} boutiques sélectionnées'"
        :maxSelectedLabels="3" 
        class="form-control" />
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/fr';
/*
import Multiselect from 'vue-multiselect';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/fr';
import 'vue-multiselect/dist/vue-multiselect.css';
*/
import MultiSelect from 'primevue/multiselect';


export default {
  components: {
    DatePicker,
    MultiSelect,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cookie', ['user']),
    ...mapGetters('search', ['shortcuts']),
    ...mapGetters('store', ['stores']),
  },
  methods: {
    ...mapActions('store', ['fetchStores']),
  },
  async mounted() {
    await this.fetchStores();
    const store = this.filters.find(filter => filter.field == 'store_id');
    
    if (store) {
      store.options = this.stores.map(store => ({
        value: store.store_id,
        label: store.name,
      }));
      
      store.value = this.user.stores.map(store => ({
        value: store.store_id,
        label: store.name,
      }));
    }
  }
};
</script>

<style scoped>
.p-multiselect {
    border: var(--bs-border-width) solid var(--bs-border-color);
    box-shadow: none;
}
.p-multiselect:hover {
    border: var(--bs-border-width) solid var(--bs-border-color);
    box-shadow: none;
}
.p-multiselect-dropdown {
  justify-content: flex-end;
}
</style>
