<template>
  <div class="modal fade" id="addCustomerModal" tabindex="-1" aria-labelledby="addCustomerModal" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title">
            {{ step == 'search' ? 'Chercher un client' : 'Créer un nouveau client' }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <!-- Chercher un client -->
        <div v-if="step == 'search'" class="modal-body">
          <search-bar :keywords="keywords" placeholder="Tapez un nom ou une adresse e-mail ..." @search="onSearch" />
          
          <div v-if="search.loading" class="text-center py-3">
            <loader />
          </div>

          <div v-else-if="search.customers.length" class="table-responsive">
            <table class="table table-striped text-start">
              <thead>
                <tr>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Adresse e-mail</th>
                  <th>Date de naissance</th>
                  <th>Code postal</th>
                  <th class="text-center">Ajouter</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(customer, index) in search.customers" :key="index">
                  <td>{{ customer.firstname }}</td>
                  <td>{{ customer.lastname }}</td>
                  <td>{{ customer.email }}</td>
                  <td>{{ customer.dob }}</td>
                  <td>{{ customer.addresses && customer.addresses.length ? customer.addresses[0].postcode : '-' }}</td>
                  <td class="text-center">
                    <button type="button" class="btn btn-success btn-sm" @click="addCustomer(customer)" data-bs-dismiss="modal">Ajouter</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else-if="keywords" class="text-center py-3">
            Aucun client n'a été trouvé.
          </div>
        </div>

        <!-- Créer un nouveau client -->
        <div v-else-if="step == 'create'" class="modal-body">

          <!-- Message d'erreur ou succès -->
          <div v-if="customer.error || customer.success" class="alert" :class="customer.error ? 'alert-warning' : 'alert-success'">
            {{ customer.error || customer.success }}
          </div>

          <div v-if="!customer.loading">
            
            <!-- Informations de base -->
            <div class="row mb-3">
              <div class="col">
                <input v-model="form.firstname" type="text" class="form-control" placeholder="Prénom*" required>
              </div>
              <div class="col">
                <input v-model="form.lastname" type="text" class="form-control" placeholder="Nom*" required>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <input v-model="form.email" type="text" class="form-control" placeholder="Adresse e-mail*" required>
              </div>
              <div class="col">
                <input v-model="form.telephone" type="text" class="form-control" placeholder="Numéro de téléphone">
              </div>
            </div>

            <!-- Afficher ou masquer l'adresse -->
            <div class="col-auto mb-3">
              <button type="button" class="btn btn-outline-secondary w-100" @click="submitAddress = !submitAddress">
                {{ submitAddress ? 'Annuler l\'adresse' : 'Ajouter une adresse' }}
              </button>
            </div>

            <!-- Formulaire d'adresse -->
            <div v-if="submitAddress" class="border-top pt-3">
              <div class="row mb-3">
                <div class="col">
                  <input v-model="form.address.street[0]" type="text" class="form-control" placeholder="Numéro et rue">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <input v-model="form.address.street[1]" type="text" class="form-control" placeholder="Appartement, bâtiment, étage ...">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <input v-model="form.address.postcode" type="text" class="form-control" placeholder="Code postal">
                </div>
                <div class="col">
                  <input v-model="form.address.city" type="text" class="form-control" placeholder="Ville">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <select v-model="form.address.region" class="form-select">
                    <option v-for="region in regions" :key="region.region_id" :value="region">
                      {{ region.default_name }}
                    </option>
                  </select> 
                </div>
                <div class="col">
                  <select v-model="form.address.country_id" class="form-select"> 
                    <option v-for="country in countries" :key="country.country_id" :value="country.country_id">
                      {{ country.country_id }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <input v-model="form.address.telephone" type="tel" class="form-control" placeholder="Téléphone">
                </div>
              </div>
            </div>
          </div>

          <div v-else class="text-center py-3">
            <loader />            
          </div>
          
        </div>

        <div class="modal-footer">
          <button v-if="step == 'search'" type="button" class="btn btn-secondary" @click="step = 'create'">Créer un nouveau client</button>
          <button v-if="step == 'create'" type="button" class="btn btn-secondary" @click="step = 'search'">Retour à la recherche</button>
          <button v-if="step == 'create'" type="button" class="btn btn-primary" @click="onCreate">Valider</button>
          <button v-if="close" type="button" class="btn btn-success" data-bs-dismiss="modal" aria-label="Close">Retour au panier</button>
        </div>

      </div>
    </div>
  </div>
</template>



<script>
import { mapActions, mapGetters } from 'vuex';
import SearchBar from '@/components/common/SearchBar';

export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      close: false,
      step: 'search',
      keywords: '',
      submitAddress: false,
      form: {
        firstname: null,
        lastname: null,
        email: null,
        newsletter: true,
        address: {
          street: [],
          postcode: null,
          city: null,
          region: {
            region_id: null,
            code: null,
            default_name: null,
          },
          country_id: 'FR',
          telephone: null,
        }
      }
    }
  },
  computed: {
    ...mapGetters('address', [
      'countries', 
      'regions'
    ]),
    ...mapGetters('cart', [
      'cart'
    ]),
    ...mapGetters('customer', [
      'customer'
    ]),
    ...mapGetters('search', [
      'search'
    ]),
  },
  watch: {
    'form.address.postcode'(newPostcode) {
      if (newPostcode && newPostcode.length >= 2) {
        this.setRegion(newPostcode);
      }
    }
  },
  methods: {
    ...mapActions('address', [
      'fetchCountries', 
      'fetchRegions'
    ]),
    ...mapActions('cart', [
      'updateAddress',
    ]),
    ...mapActions('customer', [
      'createCustomer', 
      'clearCustomer'
    ]),
    ...mapActions('search', [
      'fetchCustomers', 
      'clearSearch'
    ]),
    setRegion(postcode) {
      let regionCode = postcode.substring(0, 2);
      if (regionCode.startsWith('0')) {
        regionCode = regionCode.substring(1);
      }
      const matchedRegion = this.regions.find(region => region.code == regionCode);
      if (matchedRegion) {
        this.form.address.region = matchedRegion;
      } else {
        this.form.address.region = {
          region_id: null,
          code: null,
          default_name: null,
        };
      }
    },
    onSearch(keywords) {
      this.keywords = keywords;
      const filters = {
        name: { value: keywords, condition: 'like' },
        email: { value: keywords, condition: 'like' },
      };
      const operation = 'or';
      const limit = 10;
      this.fetchCustomers({ filters, operation, limit });
    },
    async onCreate() {
      if (this.submitAddress) {
        const response = await this.createCustomer(this.form);
        if (response && response.success) {
          this.addCustomer(response.success.results);
          await this.clearCustomer();
            this.customer.success = 'Client créé et ajouté avec succès.'
            this.customer.loading = false;
            this.close = true;
        }
      } else {
        this.updateAddress({ type: 'billing', address: {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          email: this.form.email,
          telephone: this.form.telephone,
          street: this.cart.billing_address.street,
          postcode: this.cart.billing_address.postcode,
          city: this.cart.billing_address.city,
          region_id: this.cart.billing_address.region_id,
          country_id: this.cart.billing_address.country_id,
          updateBothAddress: false,
        }});
      }
    },
    addCustomer(customer) {
      this.$emit('add-customer', customer);
    },
  },
  mounted() {
    this.fetchCountries();
    this.fetchRegions(this.form.address.country_id);
  },
  unmounted() {
    this.clearSearch();
  }
};
</script>
