<template>

  <div class="row justify-content-center mt-5">
    <div class="col-auto h5 fw-bold">Historique de la commande</div>
  </div>

  <div class="row justify-content-center">
    <div class="col-12">
      
      <table v-if="displayState(order.status)" class="table border table align-middle fs-sm">
        <tbody v-if="order.status_histories.length > 0">
          <tr v-for="(state, index) in order.status_histories" :key="index">
            <td class="col-1 text-center text-nowrap">{{ formatDate(state.created_at, 'slash') }} <br> {{ formatDate(state.created_at, 'time') }}</td>
            <td class="col-1 text-nowrap">
              <span v-if="displayState(state.status)" class="d-flex">
                <i :class="`bi ${displayState(state.status).icon} me-1 ${displayState(state.status).color}`"></i>{{ displayState(state.status).name }}
              </span>
              <span v-else class="d-flex">
                Aucun statut
              </span>
            </td>
            <td class="col-xxl-12 text-start text-break" @click="state.expanded = !state.expanded" style="cursor: pointer;">
              <span v-if="state.expanded">{{ state.comment }}</span>
              <span v-else v-html="truncateText(state.comment)"></span>
            </td>
          </tr>
        </tbody>
        <tbody v-else class="text-center">
          <tr>
            <td class="col-1 text-center text-nowrap">{{ formatDate(order.updated_at, 'slash') }} <br> {{ formatDate(order.updated_at, 'time') }}</td>
            <td class="col text-nowrap">
              <span v-if="displayState(order.status)" class="d-flex">
                <i :class="`bi ${displayState(order.status).icon} me-1 ${displayState(order.status).color}`"></i>{{ displayState(order.status).name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row justify-content-start mb-3">
    <div class="col">
      <select v-if="selectState" class="form-select form-select-sm" v-model="status">
        <option value="" disabled selected>Choisir un statut</option>
        <option value="processing">Paiement accepté</option>
        <option value="canceled">Annulée</option>
        <option value="order_delayed">Différée</option>
        <option value="order_available_in_shop">Disponible en boutique</option>
        <option value="complete">Expédiée</option>
        <option value="exception_order">Exceptionelle</option>
        <option value="unclaimed_order">Non réclamée</option>
        <option value="partial_order">Nouvelle expédition</option>
        <option value="complete">Expédiée</option>
      </select>
    </div>
    <div class="col-auto">
      <button v-if="status" class="btn btn-sm float-end btn-success" @click="updateStatus">Appliquer ce statut</button>
      <button v-else class="btn btn-sm float-end btn-outline-primary" @click="showStateForm">Changer le statut</button>
    </div>
  </div>
  
  <div v-if="status" class="row justify-content-start"> 
    <div class="col">
      <input class="form-control form-control-sm" placeholder="Commentaire privé (facultatif)">
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';

export default {
  data() {
    return {
      selectState: null,
      status: '',
    };
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('states', [
      'states'
    ]),
  },
  methods: {
    ...mapActions('order', [
      'updateOrderStatus',
      'createInvoice',
    ]),
    ...mapActions('states', [
      'fetchOrderStates',
    ]),
    formatDate,
    sanitizeHTML(text) {
      return text ? text.replace(/<\s*br\s*\/?\s*>/gi, '<br>') : '';
    },
    truncateText(text) {
      const maxLength = 44;
      if (text && text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      } return text;
    },
    showStateForm() {
      this.selectState = !this.selectState;
      if (!this.selectState) {
        this.status = '';
      }
    },
    displayState(status) {
      return this.states && this.states.orders ? this.states.orders.find(state => state.order_status == status) : null;
    },
    updateStatus() {
      if (this.status == 'processing') {
        if (confirm('Voulez vous vraiment générer une facture ?'))
          this.createInvoice();
      } else {
        this.updateOrderStatus(this.status);
      }
    },
  },
  mounted() {
    this.fetchOrderStates();
    this.order.status_histories.forEach(state => {
      state.expanded = false;
    });
  }
};
</script>
