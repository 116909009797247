<template>
  <div class="col-12">
    <div v-if="component.error" class="row h-128">
      <div class="col-12">
        {{ component.error }}
      </div>
    </div>
    <div v-else-if="component.loading" class="row h-128">
      <loader />
    </div>

    <div v-else-if="order.invoices" class="row h-128">
      <template v-for="(invoice, index) in order.invoices" :key="index">
        <article class="col-auto" :id="index">
          <div class="card h-100" role="button" @click.prevent="loadInvoice(invoice)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="card-text fw-bold">F#{{ invoice.increment_id }}</div>
              </div>
              <div class="row">
                <div class="card-text">{{ invoice.base_grand_total }} €</div>
              </div>
              <div class="row">
                <div class="card-text">{{ formatDate(invoice.created_at, 'slash') + ' - ' + formatDate(invoice.created_at, 'time')}}</div>
              </div>
            </div>
          </div>
        </article>
      </template>
    </div>

    <div v-else class="row h-128">
      <div class="col-12">
        Aucune facture sur cette commande
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      component: {
        loading: true,
        error: null
      },
    };
  },
  computed: {
    ...mapGetters('order', ['order']),
  },
  methods: {
    ...mapActions('order', ['fetchOrderInvoices']),
    formatDate,
    loadInvoice(element) {
      this.$emit('load-workplan', 'OrderInvoice', element);
    },
  },
  async mounted() {
    if (!this.order.invoices) {
      await this.fetchOrderInvoices();
    } this.component.loading = false;
  }
};
</script>