<template>
  <div class="container-fluid vh-100 d-flex flex-column lh-1 placeholder-glow">
    <navbar v-if="cookie && cookie.token" @logout="logout" class="navbar" />
    <alerts v-if="alert && alert.show" :message="alert.message" :style="alert.style" @dismiss="clearAlert" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Alerts from '@/components/nav/Alerts';
import Navbar from '@/components/nav/Navbar';

export default {
  components: {
    Alerts,
    Navbar,
  },
  computed: {
    ...mapGetters('alerts', [
      'alert'
    ]),
    ...mapGetters('cookie', [
      'cookie'
    ]),
  },
  methods: {
    ...mapActions('alerts', [
      'clearAlert'
    ]),
    ...mapActions('cookie', [
      'logout'
    ]),
  },
};
</script>

<style>
@import url('assets/fonts.css');
::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
.font-ean {
  font: 50px 'code128';
}
.square-64 {
  width: 64px;
  height: 64px;
}
.mw-152 {
  min-width: 152px;
}
.w-10 {
  width: 10% !important;
  max-width: 10% !important;
}
.w-33 {
  width: 33% !important;
  max-width: 33% !important;
}
.h-32 {
  height: 32px;
  min-height: 32px;
}
.h-64 {
  height: 64px;
  min-height: 64px;
}
.h-120 {
  height: 120px;
  min-height: 120px;
}
.h-128 {
  height: 128px;
  min-height: 128px;
}
.h-256 {
  height: 256px;
  min-height: 256px;
}
.start-75 {
  left: 75%;
}
.fs-sm {
  font-size: 0.8rem;
}
.fs-md {
  font-size: 0.9rem;
}
.lh-md {
  line-height: 1.5;
}
.add {
  color: #C3CFD9;
}
.card {
  cursor: pointer;
}
.selected, 
.selected:hover {
  background-color: var(--bs-primary-bg-subtle);
}
.nav li:hover, 
.nav li.active {
  background: var(--bs-light);
}
.placeholder {
  border-radius: 25px;
}
</style>
