<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div class="row p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Fonds de caisse à {{ user.store.name }}</h1>
        </div>
      </div>

      <div class="row p-5 align-items-center justify-content-between">

        <div class="col-12 col-md-4 col-xl-3 dummy"></div>
        
        <!-- Montant total -->
        <div class="col-6 col-md-4 col-xl-3 mx-auto text-center">
          <label for="totalAmount" class="form-label">Montant total calculé</label>
          <div class="input-group mb-3">
            <input type="number" v-model="totalAmountInput" id="totalAmount" class="form-control" @input="updateTotalAmount">
            <span class="input-group-text">€</span>
          </div>
        </div>
        
        <!-- Bouton historique -->
        <div class="col-6 col-md-4 col-xl-3 text-end">
          <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#cashierHistory" aria-controls="cashierHistory" @click="fetchStoreCashierHistory()">
            Historique
          </button>
        </div>

      </div>

      <!-- Enregistrer -->
      <div class="row border-bottom pb-5">
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary" @click="submitCashier">Enregistrer le fond de caisse</button>
        </div>
      </div>

      <!-- Centimes -->
      <div class="row p-5 border-bottom flex-wrap">
        <div v-for="(value, index) in centimes" :key="index" class="col-6 col-md-4 col-xl-2 text-center mb-3">
          <label :for="'unit-' + index" class="form-label">{{ value.label }}</label>
          <div class="input-group mb-3">
            <input type="number" v-model="value.count" :id="'unit-' + index" class="form-control" min="0" @input="updateTotalAmount">
            <span class="input-group-text">pièces</span>
          </div>
        </div>
      </div>

      <!-- Pièces -->
      <div class="row p-5 border-bottom flex-wrap">
        <div v-for="(value, index) in euros" :key="index" class="col-6 col-md-4 col-xl-2 text-center mb-3">
          <label :for="'unit-' + index" class="form-label">{{ value.label }}</label>
          <div class="input-group mb-3">
            <input type="number" v-model="value.count" :id="'unit-' + index" class="form-control" min="0" @input="updateTotalAmount">
            <span class="input-group-text">pièces</span>
          </div>
        </div>
      </div>

      <!-- Billets -->
      <div class="row p-5 border-bottom flex-wrap">
        <div v-for="(value, index) in billets" :key="index" class="col-6 col-md-4 col-xl-2 text-center mb-3">
          <label :for="'unit-' + index" class="form-label">{{ value.label }}</label>
          <div class="input-group mb-3">
            <input type="number" v-model="value.count" :id="'unit-' + index" class="form-control" min="0" @input="updateTotalAmount">
            <span class="input-group-text">billets</span>
          </div>
        </div>
      </div>

      <!-- Historique -->
      <div class="offcanvas offcanvas-end w-33" data-bs-scroll="true" tabindex="-1" id="cashierHistory" aria-labelledby="cashierHistory">
        <div class="offcanvas-header">
          <div class="offcanvas-title fs-5" id="cashierHistory">Historique de fonds de caisse</div>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <table v-if="store.cashier" class="table table-striped">
            <thead>
              <tr>
                <th>Employé·e</th>
                <th class="text-end">Montant</th>
                <th class="text-end">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cashier, index) in store.cashier" :key="index">
                <td>{{ cashier.firstname }} {{ cashier.lastname }}</td>
                <td class="text-end">{{ cashier.funds_amount }} €</td>
                <td class="text-end">{{ cashier.date_add }}</td>
              </tr>
            </tbody>
          </table>
          <loader v-else />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      centimes: [
        { label: '1 centime', value: 0.01, count: 0 },
        { label: '5 centimes', value: 0.05, count: 0 },
        { label: '10 centimes', value: 0.10, count: 0 },
        { label: '20 centimes', value: 0.20, count: 0 },
        { label: '50 centimes', value: 0.50, count: 0 },
      ],
      euros: [
        { label: '1 euro', value: 1.00, count: 0 },
        { label: '2 euros', value: 2.00, count: 0 },
      ],
      billets: [
        { label: '5 euros', value: 5.00, count: 0 },
        { label: '10 euros', value: 10.00, count: 0 },
        { label: '20 euros', value: 20.00, count: 0 },
        { label: '50 euros', value: 50.00, count: 0 },
        { label: '100 euros', value: 100.00, count: 0 },
        { label: '200 euros', value: 200.00, count: 0 },
        { label: '500 euros', value: 500.00, count: 0 },
      ],
      totalAmountInput: 0,
    };
  },
  watch: {
    totalAmount(value) {
      this.totalAmountInput = value;
    },
    'user.store': {
      handler(new_store, old_store) {
        if (new_store !== old_store) {
          this.fetchStore(new_store);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('store', ['store']),
    ...mapGetters('cookie', ['user']),
    totalAmount() {
      const centimes = this.centimes.reduce((total, unit) => total + unit.value * unit.count, 0);
      const euros = this.euros.reduce((total, unit) => total + unit.value * unit.count, 0);
      const billets = this.billets.reduce((total, unit) => total + unit.value * unit.count, 0);
      return centimes + euros + billets;
    },
  },
  methods: {
    ...mapActions('store', ['fetchStore', 'fetchStoreCashierHistory', 'insertStoreCashier']),
    updateTotalAmount() {
      this.totalAmountInput = parseFloat(this.totalAmountInput.toFixed(2));
    },
    submitCashier() {
      this.insertStoreCashier({
        id_store: this.store.id_store,
        id_user: this.user.id_user,
        funds_amount: this.totalAmountInput, 
      });
    },
  },
  created() {
    this.fetchStore(this.user.store);
  },
};
</script>
